<template>
  <div>
    
  </div>
</template>

<script>
import QRCode from 'qrcodejs2';
export default {
  name: 'dashboard',
  data() {
    return {
    }
  },
  methods: {
    getPayContent() {
      this.$http.doApi('fw-wx-pay-getPayContent', {
        money: this.money
      }).then(data => {
        new QRCode(this.$refs.payQRCode, {
          text: data.val,
          width: 100,
          height: 100,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        });
      });
    }
  }
}
</script>

<style>

</style>